import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper"
import Quote from "/public/site-assets/svg/quote.svg"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export default function LatestTestimonials({
    global,
    pathPrefix,
    customClass = "",
    content,
    swiperID,
    post

}) {
    const settings = {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        spaceBetween: 30,
        slidesPerView: 1,
        speed: 500,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            clickable: true,
            el: `.swiper-custom-paginations .${swiperID}`,
        },
        // navigation: {
        //     nextEl: `.${swiperID}.swiper-next`,
        //     prevEl: `.${swiperID}.swiper-prev`
        // },
        breakpoints: {
            767: {
                slidesPerView: 2,
            }
        }
    }
    return (
        <section className={`${styles.root} ${customClass} py-1`}>
            <Container>
                <div className="wrapper rounded-4 secondary-bg p-4">
                    {content ?
                        <div className="text-center my-4">
                            <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                                {content}
                            </SourceFlowText>
                        </div>
                        : null}
                    <div className="mt-4">
                        <Swiper {...settings}>
                            {post?.map((post, index) => (
                                <SwiperSlide key={index}>
                                    <Card className="border-0 bg-transparent py-4 px-md-5 px-4">
                                        <span className="quote-icon">
                                            <Quote />
                                        </span>
                                        {post?.content ?
                                            <div className="post-description" dangerouslySetInnerHTML={{ __html: post?.content }} />
                                            : null}
                                        <p className="m-0"><strong>{post?.title}</strong></p>
                                        {post?.position ?
                                            <p className="post-position"><small>{post?.position}</small></p> : null}
                                    </Card>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="text-center swiper-custom-paginations mt-4">
                            <div className={`${swiperID}`} />
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}