import { Global, Collection, Jobs } from "@sourceflow-uk/sourceflow-sdk";
import SeoHead from "../components/SeoHead";
import LatestAwards from "../components/LatestAwards";
import LatestNews from "../components/LatestNews";
import MainBanner from "../components/MainBanner";
import LatestJobs from "../components/LatestJobs";
import ImageWithVideoBanner from "../components/ImageWithVideoBanner";
import ImageWithTextWithOverlay from "../components/ImageWithTextWithOverlay";
import SurveyText from "../components/SurveyText";
import LatestTestimonials from "../components/LatestTestimonials";

import MultiColumnCTA from "../components/MultiColumnCTA";

export default function Home({
  global,
  all_awards,
  all_news,
  all_jobs,
  all_solutions,
  all_sectors,
  all_testimonials,
}) {
  return (
    <>
      <SeoHead />
      <MainBanner
        global={global}
        pathPrefix={`homepage.main_banner`}
        customClass={``}
        content={
          <>
            <h1>
              Industry Leaders in Life<br></br>Sciences Recruitment
            </h1>
            <p>
              SRG is the leader in the Life Sciences human capital industry. We
              apply three decades of specialized knowledge to a full spectrum of
              clinical, quality, regulatory, and compliance talent solutions. We
              recruit across the Life Sciences product life cycle—covering
              scientific research and development (R&D), clinical trials,
              manufacturing, and engineering disciplines.
            </p>
            <p>
              Together, we create world-class communities that empower
              individuals and businesses to shape tomorrow’s world.
            </p>
          </>
        }
        imageURL1={`/site-assets/images/main-banner.webp`}
        imageALT1={`Science lab workers looking at test tube`}
        imageURL2={`/site-assets/images/main-banner-overlay.jpg`}
        imageALT2={`Overlay Main Banner`}
        jobSearch={true}
        pageAnchor={`more-info-of-us`}
        h1_content={
          <>
            Industry Leaders in <br></br>Life Sciences Recruitment
          </>
        }
      />
      <ImageWithTextWithOverlay
        global={global}
        pathPrefix={`salary_survey_page.image_with_text_with_overlay`}
        customClass={`py-5 `}
        backgroundColor={"#ffffff"}
        content={
          <>
            <h2>Ask David</h2>
            <p>
              At Lorien, we put people first. Building trust through genuine,
              honest relationships is one of our core values and underpins
              everything we do. Ask David is all about resolving problems
              quickly, helpfully and at the source.
            </p>
            <p>
              As a concept, it’s simple: if you’re a current contractor at
              Lorien with a question, concern or request, then get in touch
              using the below contact form and David will respond directly to
              you himself within 24 hours. With more than 20 years’ experience
              in recruitment at all levels and operations, David can provide
              expert guidance and support on almost any recruitment-related
              subject (and if he can’t, he’ll put you in touch with someone who
              can).
            </p>
          </>
        }
        buttonTitle={`Download Now`}
        buttonLink={`/global-talent-trends-and-insights-2024/`}
        imageURL={`/site-assets/images/STEM-Talent-Trends-and-Insights-Laptop-Mock-Up.webp`}
        imageALT={`Job Seekers Featured Image`}
        popUpModal_1={false}
      />
      {/* <SurveyText
        global={global}
        pathPrefix={`homepage.survey_text.header`}
		customClass={``}
		editableText={true}
        content={
          <>
            <h5>
              The Global STEM Salary Survey 2024: Helping to shape STEM's future
            </h5>
            <br></br>
            <p>
              SRG’s annual Global Life Science, Clinical and Engineering
              Insights Survey is created – in association with New Scientist
              Jobs – to gain and provide such knowledge and insights, help us
              benchmark vital data and discover the information STEM companies
              need to deliver fair, competitive and – most importantly –
              attractive salaries and employment prospects.
            </p>
            <br></br>
            <p>
              Click the link below to take part – as a thank you, all
              respondents who complete the survey will be entered to win a £250
              Amazon voucher.
            </p>
          </>
        }
        buttonTitle={`Take the survey`}
		buttonLink={`https://bit.ly/48UAumJ`}
      /> */}
      <ImageWithVideoBanner
        global={global}
        pathPrefix={`homepage.image_with_video_banner`}
        sectionId={`what-we-do`}
        customClass={`py-5`}
        content={
          <>
            <h2>It's What We Do</h2>
            <p>
              Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
          </>
        }
        buttonTitle={`Learn More About What We Do`}
        buttonLink={`/`}
        videoLink={`https://www.youtube.com/watch?v=zjC4RvsWsdk`}
        bannerImage={``}
        imageURL={`/site-assets/images/what-we-do.webp`}
        imageALT={`It's what we do`}
        videoGalleryClass={`WhatWeDo`}
        pageAnchor={`more-info-of-us`}
      />
      <div className="py-5">
        <ld-slot name="Homepage Client Solutions"></ld-slot>
      </div>
      <LatestJobs
        global={global}
        pathPrefix={`homepage.latest_jobs`}
        customClass={`py-5`}
        post={all_jobs}
        content={
          <>
            <h2>Hot Jobs</h2>
            <p>Find the best tech and digital career opportunities.</p>
          </>
        }
        buttonTitle={`View All Jobs`}
        buttonLink={`/jobs/`}
        swiperID={`latestJobsSwiper`}
      />


      <MultiColumnCTA
        global={global}
        pathPrefix={`homepage.multi_column_cta`}
        sectionId={`more-info-of-us`}
        customClass={`py-5`}
        left_content={
          <>
            <h2>Market's We Recruit</h2>
          </>
        }
        right_content={
          <>
            <h2>Business Solutions</h2>
          </>
        }
        left_post={all_sectors}
        right_post={all_solutions}
        leftColumnFilterUrl={`sectors/`}
      />
      <LatestTestimonials
        global={global}
        pathPrefix={`homepage.latest_testimonials`}
        customClass={`py-5`}
        content={
          <>
            <h2>Candidate Testimonials</h2>
          </>
        }
        post={all_testimonials}
        swiperID={`latestTestimonialsSwiper`}
      />
      <LatestNews
        global={global}
        pathPrefix={`homepage.latest_news`}
        customClass={`py-5`}
        post={all_news}
        content={
          <>
            <h2>The Lorien Pulse: Latest News, Events, and Insights</h2>
            <p>
              Our tech expertise and partner-led approach allows us to deliver
              highly successful results for our clients so they can attract and
              retain unique talent for their business. Explore our latest
              collection of case studies, news, events, and insights
            </p>
          </>
        }
        buttonLink={`/blog/`}
        buttonTitle={`View All Insights`}
      />
      <LatestAwards
        global={global}
        pathPrefix={`homepage.latest_awards`}
        customClass={`pt-5`}
        content={
          <>
            <h2>Awards & Industry Recognition</h2>
          </>
        }
        post={all_awards}
        buttonLink={``}
        buttonTitle={``}
        swiperID={`latestAwardsSwiper`}
      />
    </>
  );
}

export async function getStaticProps({ params }) {
  const global = (await Global()).toJson();
  const awards = await Collection("awards_industry_recognition");
  const solutions = await Collection("solutions");
  const sectors = await Collection("sectors_uk");

  const testimonials = (await Collection("candidate_testimonials")).filter(
    function (testimonial) {
      return testimonial.tags?.en == "Homepage";
    }
  );

  const news = (await Collection("news")).filter(function (post) {
    return post.country?.en == "United Kingdom" || post.country?.en == "All";
  });

  const all_jobs = (await Jobs())
    .filter(function (job) {
      return (
        job.categories
          // Pulls Sectors only
          .find(
            (cat) =>
              cat.id === "b4b8be3c-ba95-4912-aa85-2cbc1264449d" &&
              cat.values.find((x) => x.name === "United Kingdom")
          )
      );
    })
    .filter(function (job) {
      return new Date(job?.expires_at) >= new Date();
    })
    .getItems()
    .slice(0, 8);

  const all_solutions = solutions.orderBy("order").getItems();
  const all_sectors = sectors.orderBy("order").getItems();
  const all_awards = awards.orderBy("order").getItems();
  const all_news = news.orderBy("date").getItems().slice(0, 8);

  const all_testimonials = testimonials.orderBy("order").getItems();
  return {
    props: {
      global,
      all_awards,
      all_news,
      all_jobs,
      all_solutions,
      all_sectors,
      all_testimonials,
    },
  };
}

// -- Reference -- //
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflow-head
// https://github.com/sourceflow-uk/components/pkgs/npm/sourceflowtext
